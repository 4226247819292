.App {
  width: 100vw;
  height: 200vh;
  padding: 0px 20px;
  background-color: #f2f0ef;
  box-shadow: 0px 10px 20px -5px rgba(29, 18, 10, 0.617);

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  text-align: center;

  overflow-x: hidden;
}

body {
  margin: 0;
  /* overflow-y: hidden; */
  background-color: #f2f0ef;
}

.transitions-wrapper {
  position: relative;
  overflow: hidden;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  background-color: #f2f0ef;
}
.right-enter {
  transform: translateX(100%);
}

.right-enter-active {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}

.right-exit {
  transform: translateX(0);
}

.right-exit-active {
  transform: translateX(-100%);
  transition: transform 300ms ease-in-out;
}

.left-enter {
  /* z-index: 0; */
  transform: translateX(-100%);
}

.left-enter-active {
  /* z-index: 1; */
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
  /* transition-timing-function: ease-in-out; */
}

.left-exit {
  /* z-index: 1; */
  transform: translateX(0);
}

.left-exit-active {
  /* z-index: 0; */
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
  /* transition-timing-function: ease-in-out; */
}
